import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/logo.webp";
import { SlArrowDown } from "react-icons/sl";
import { Link, useLocation } from "react-router-dom";
import PrimaryButton from "./common/PrimaryButton";
import { AuthContext } from "../context/AuthContext";

type LinkType = {
  name: string;
  path: string;
  href: string;
  target?: string;
  options?: LinkType[];
  active?: boolean;
};

const links: LinkType[] = [
  {
    name: "About Us",
    path: "/about-us",
    href: "#",
    active: false,
  },
  {
    name: "Our Process",
    path: "/our-process",
    href: "",
    active: false,
  },
  {
    name: "Blog",
    path: "/blogs",
    href: "",
    active: false,
  },
  {
    name: "Job Board",
    path: "/job-board",
    href: "",
    active: false,
  },
  {
    name: "Sectors",
    href: "",
    path: "/",
    active: false,
    options: [
      {
        name: "Engineering",
        path: "/engineering",
        href: "",
      },
      {
        name: "Civil Engineering",
        href: "",
        path: "/civil-engineering",
      },
      {
        name: "Infrastructure",
        href: "",
        path: "/infrastructure",
      },
    ],
  },
  {
    name: "Contact Us",
    path: "/contact",
    href: "#",
    active: false,
  },
];

const Nav = () => {
  const location = useLocation();
  const [isOpen, setisOpen] = useState(false);

  const authContext = useContext(AuthContext);

  const [navLinks, setNavLinks] = useState<LinkType[]>(links);

  const [isJobCreatePath, setIsJobCreatePath] = useState(false);

  const setActiveNav = (pageName: string) => {
    const newNavLinks = links.map((item) => {
      item.active = false;
      if (item.name === pageName) {
        item.active = true;
      }
      return item;
    });
    setNavLinks(newNavLinks);
  };

  useEffect(() => {
    if (location.pathname === "/create-job") {
      setIsJobCreatePath(true);
    } else {
      setIsJobCreatePath(false);
    }

    links.forEach((item) => {
      if (item.path === location.pathname) {
        setActiveNav(item.name);
      }
      if (item.options && item.options.length > 0) {
        item.options.forEach((option) => {
          if (option.path === location.pathname) {
            setActiveNav(item.name);
          }
        });
      }
    });

    if (window.location.pathname === "/" && window.location.hash === "") {
      setActiveNav("Home");
    }
  }, [location]);

  if (!authContext) return null;

  return (
    <nav className="py-3 fixed z-50 w-full bg-white shadow-md">
      <div className="flex inter-regular flex-wrap items-center justify-between max-w-screen-2xl px-2 sm:px-8 mx-auto">
        {!isJobCreatePath ? (
          <>
            <Link to="/" onClick={() => setActiveNav("Home")}>
              <img src={logo} className="h-7 sm:h-10" alt="TechQilla" />
            </Link>
            <div className="flex items-center lg:order-2">
              {!authContext.isAuthenticated ? (
                <Link to="https://search.aesrecruitment.com/" target="_blank">
                  <PrimaryButton className="">Login</PrimaryButton>
                </Link>
              ) : window.location.pathname === "/blogs" ? (
                <Link to="/create-blog">
                  <PrimaryButton className="bg-white !text-primary border border-primary">
                    Create Blog
                  </PrimaryButton>
                </Link>
              ) : window.location.pathname === "/create-blog" ? (
                <Link to="/blogs">
                  <PrimaryButton className="bg-white !text-primary border border-primary">
                    Preview
                  </PrimaryButton>
                </Link>
              ) : (
                <Link to="/create-job">
                  <PrimaryButton className="bg-white !text-primary border border-primary">
                    Create Job
                  </PrimaryButton>
                </Link>
              )}
              <button
                onClick={() => setisOpen(!isOpen)}
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className="items-center font-montserrat w-full lg:flex lg:w-auto lg:-ml-80 lg:order-1 "
              id="mobile-menu-2"
            >
              <div
                className={`${
                  isOpen ? "flex" : "hidden"
                } flex-col mt-4 font-medium items-center lg:flex-row lg:flex lg:space-x-6 lg:mt-0`}
              >
                {navLinks.map((link, index) => (
                  <div key={index}>
                    {link.options && (
                      <div className="group relative py-2 lg:py-0">
                        <div className="flex items-center">
                          <span
                            className={`text-sm cursor-pointer ${link.active ? "text-primary" : "text-black"}`}
                          >
                            {link.name}
                          </span>
                          <SlArrowDown className="ml-1" fontSize={10} />
                        </div>
                        <div className="z-20 absolute -right-24 w-60 lg:-ml-12 -mt-1 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                          <div className="py-1">
                            {link.options.map((option, index) => (
                              <Link
                                to={option.path}
                                key={index}
                                onClick={() => {
                                  setActiveNav(link.name);
                                  setisOpen(false);
                                  window.scrollTo(0, 0);
                                }}
                                className="block px-4 py-2 text-sm text-gray-700 bg-white hover:bg-gray-100"
                              >
                                {option.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {!link.options && (
                      <div className="relative z-10">
                        <Link
                          rel="noopener noreferrer"
                          to={link.path}
                          className={`text-sm bg-transparent z-10 ${link.active ? "text-primary" : "text-black"}`}
                          onClick={() => {
                            setActiveNav(link.name);
                            setisOpen(false);
                            window.scrollTo(0, 0);
                          }}
                        >
                          {link.name}
                        </Link>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-bold">Job Data</h2>
            {!authContext.isAuthenticated ? (
              <Link to="/login">
                <PrimaryButton className="">Login</PrimaryButton>
              </Link>
            ) : (
              <Link to="/job-board">
                <PrimaryButton className="bg-white !text-primary border border-primary">
                  Preview
                </PrimaryButton>
              </Link>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default Nav;
